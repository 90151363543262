import React, { useState, useContext } from 'react'
import { FaUser, FaUsers, FaArrowRight } from 'react-icons/fa'

import { formatDate, formatTime, formatNumber, formatDurationGoal, formatMetric, displayMeasurement } from '../src/formatter'
import { activityTypeConfig, activityTypesDistance } from '../src/activity-type-config'
import Tz from './tz'

import { UserContext } from '../contexts/user-context'

export const ChallengeObjective = ({ challenge, size='sm' }) => {
  const userHook = useContext(UserContext)
  const user = userHook.user

  const [showAllTypes, setShowAllTypes] = useState(false)

  const activitySizeStrLenSm = 30
  const activitySizeStrLenLg = 100
  const distanceBased = (challenge.activityTypesArray.length && activityTypesDistance.includes(challenge.activityTypesArray[0].toLowerCase()))

  const getRefinedTypes = (typeArray, limit = 30) => {
    let ellipsis = false
    let refinedTypes = ''
    typeArray.forEach((type, index) => {
      if (!type || !activityTypeConfig[type.toLowerCase()]) return // if new type is added this will prevent old clients from breaking
      const t = ((distanceBased && challenge.measure !== 'activityGoal') || challenge.measure === 'durationGoal') ? activityTypeConfig[type.toLowerCase()].title.default : activityTypeConfig[type.toLowerCase()].title.plural
      if (index === 0) {
        refinedTypes = t
      } else {
        if (ellipsis) return
        if (index + 1 !== typeArray.length) {
          if (refinedTypes.length > limit && (size === 'sm' || !showAllTypes)) {
            refinedTypes = `${refinedTypes}, ...`
            ellipsis = true
          } else {
            refinedTypes = `${refinedTypes}, ${t}`  
          }
        } else {
          if (refinedTypes.length > limit && (size === 'sm' || !showAllTypes)) {
            refinedTypes = `${refinedTypes}, ...`
            ellipsis = true
          } else {
            if (typeArray.length > 2) {
              refinedTypes = `${refinedTypes}, or ${t}`
            } else {
              refinedTypes = `${refinedTypes} or ${t}`
            }
          }
        }
      }
    })
    return refinedTypes
  }

  const formatDurationDisplay = (d) => {
    if (!d) return '?'
    const mins = parseInt(d, 10)
    const days = Math.round(mins / 1440)
    const hours = Math.round((mins - (mins / 1440)) / 60)
    const minutes = mins - (mins / 1440) - (mins * 60)
    if (days && (mins / 1440 > .75)) return `${days} day${days > 1 ? 's': ''}`
    if (hours) return `${hours} hour${hours > 1 ? 's': ''}`
    if (minutes) return `${minutes} minute${minutes > 1 ? 's' : ''}`
  }

  // one off request for this feature, so hard coding. If we get more requests, we'll
  // need to add format option in db. Can remove 12/31/22 if no more requests
  const formatDurationGoalOverride = (c) => {
    if (c.id === 'ae25b7a0-487e-11ed-ba11-3be8cb5f9e02') return `${formatNumber(c.durationGoal / 60)} minutes`
    return formatDurationGoal(c.durationGoal)
  }

  return <>
    {(!challenge.isRank && challenge.measure !== 'race' && challenge.measure !== 'pointGoal') && 
      <div className={`mt-2 pr-2 ${size === 'sm' ? 'text-md' : 'text-xl'} text-chCaption`}>
        {challenge.measure === 'activityGoal' && <div className='inline'>{formatNumber(challenge.activityGoal)}&nbsp;</div>}
        <span onClick={() => { setShowAllTypes(!showAllTypes)}}>{getRefinedTypes(challenge.activityTypesArray, size === 'sm' ? activitySizeStrLenSm : activitySizeStrLenLg)}</span>
        {challenge.measure === 'distance' && <div className='inline'>&nbsp;{formatMetric(challenge.distance, 0, 'lg', user.imperial)} {displayMeasurement(user.imperial)}</div>}
        {challenge.measure === 'elevation' && <div className='inline'>&nbsp;{formatMetric(challenge.elevation, 0, 'sm', user.imperial)} {displayMeasurement(user.imperial, 'sm')} in elevation</div>}
        {challenge.measure === 'durationGoal' && <>&nbsp;for a total of <div className='inline'>{formatDurationGoalOverride(challenge)}</div></>}
        {challenge.measure === 'pointGoal' && <>&nbsp;to accumulate a total of <div className='inline'>{formatNumber(challenge.pointGoal)} points</div></>}
        {challenge.measure === 'streakGoal' && <>&nbsp;every day for <div className='inline'>{formatNumber(challenge.streakGoal)} consecutive days</div></>}
        {challenge.measure !== 'streakGoal' || (challenge.measure === 'streakGoal' && challenge.streakGoal !== Math.round(challenge.duration / 1440)) && <>&nbsp;in {formatDurationDisplay(challenge.duration)}</>}
      </div>
    }

    {challenge.measure === 'pointGoal' && <div className={`mt-2 pr-2 ${size === 'sm' ? 'text-md' : 'text-xl'} text-chCaption`}>
      Accumulate <div className='inline'>{challenge.isRank ? 'as many points as possible' : `${formatNumber(challenge.pointGoal)} points`}</div>
      &nbsp;in {formatDurationDisplay(challenge.duration)}
    </div>}

    {challenge.measure === 'race' && <div className={`mt-2 pr-2 ${size === 'sm' ? 'text-md' : 'text-xl'} text-chCaption`}>
    <span className='cursor-pointer' onClick={() => { setShowAllTypes(!showAllTypes )}}>{getRefinedTypes(challenge.activityTypesArray, size === 'sm' ? activitySizeStrLenSm : activitySizeStrLenLg)}</span> a <div className='inline'>{formatMetric(challenge.distance, 2, 'lg', user.imperial)} {displayMeasurement(user.imperial)}</div>
      &nbsp;<div className='inline'>Virtual Race</div>
    </div>}

    {(challenge.isRank && challenge.measure !== 'pointGoal') && <div className={`mt-2 pr-2 ${size === 'sm' ? 'text-md' : 'text-xl'} text-chCaption`}>
      {Boolean(!challenge.activityTypesArray.length) && <span>?</span>}
      <span className='cursor-pointer' onClick={() => { setShowAllTypes(!showAllTypes )}}>{getRefinedTypes(challenge.activityTypesArray, size === 'sm' ? 20 : activitySizeStrLenLg)}</span><div className='inline'>&nbsp;as much as possible</div>
      &nbsp;in {formatDurationDisplay(challenge.duration)}
    </div>}
  </>
}

const ChallengeDetails = ({ challenge, size, archived }) => {
  const userHook = useContext(UserContext)
  const user = userHook.user

  const color = archived ? '#a3a3a3' : 'text-info'
  const mutedTextColor = 'text-gray-400'
  
  return <>
    <div className='flex items-center gap-3'>
      <div className={size === 'sm' ? 'text-sm' : 'text-xl'} style={{ color }}>
        {formatDate(challenge.start)}
        <div className={`${mutedTextColor} text-sm text-center`}>{formatTime(challenge.start)}</div>
      </div>
      <div className={`${mutedTextColor} text-sm text-center`}><FaArrowRight /></div>
      {challenge.end && <div className={size === 'sm' ? 'text-sm' : 'text-xl'} style={{ color }}>
        {formatDate(challenge.end)}
        <div className={`${mutedTextColor} text-sm text-center`}>{formatTime(challenge.end)}</div>
      </div>}
      <div className='flex items-start'><Tz challenge={challenge} /></div>
    </div>

    {challenge.isTeamChallenge && <div className={`flex mt-2 ${size === 'sm' ? 'text-md' : 'text-xl'}`} style={{ color }}><FaUsers className='mr-2' style={{ color, fontSize: '140%' }} /> Team Challenge</div>}
    {!challenge.isTeamChallenge && <div className={`flex mt-2 ${size === 'sm' ? 'text-md' : 'text-xl'}`} style={{ color }}><FaUser className='mr-2' style={{ color }} /> Individual Challenge</div>}
    
    <ChallengeObjective challenge={challenge} size={size} />

    {(challenge.measure !== 'race' && size !== 'sm' && challenge.filters && Boolean(Object.keys(challenge.filters).length)) &&
      <div className='mt-2 text-gray-400' >
        {(Boolean(challenge.filters.distance) || Boolean(challenge.filters.duration)) && <>Each activity must be&nbsp;</>}
        {Boolean(challenge.filters.distance) && <><div className='inline'>{formatMetric(challenge.filters.distance, 2, 'lg', user.imperial)} {user.imperial ? 'mile' : 'kilometer'}{formatMetric(challenge.filters.distance, 2, 'lg', user.imperial) > 1 && 's'}</div> or greater</>}
        {(Boolean(challenge.filters.distance) && (challenge.filters.duration || challenge.filters.maxPoints)) && <>&nbsp;and<br/></>}
        {Boolean(challenge.filters.duration) && <>at least <div className='inline'>{challenge.filters.duration / 60} minutes</div> or longer</>}
        {(Boolean(challenge.filters.distance) || Boolean(challenge.filters.duration)) && <>&nbsp;to qualify.&nbsp;</>}
        {challenge.filters.maxPoints && <><div className='inline'>{formatNumber(challenge.filters.maxPoints)} points</div> is the maximum points for a single activity.</>}
      </div>
    }
  </>
}

export default ChallengeDetails
