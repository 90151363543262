const config = {
  run: {
    title: {
      default: 'Run',
      plural: 'Runs'
    },
    type: 'distance',
    multiplier: 3, // pts for point challenges
    measure: 'distance', // for pts configs, slightly diff than type
    hasElevation: true
  },
  trailrun: {
    parent: 'run',
    title: {
      default: 'Trail Run',
      plural: 'Trail Runs'
    },
    type: 'distance',
    multiplier: 3.25,
    measure: 'distance',
    hasElevation: true
  },
  virtualrun: {
    title: {
      default: 'Virtual Run',
      plural: 'Virtual Runs'
    },
    type: 'distance',
    multiplier: 3,
    measure: 'distance',
    hasElevation: true
  },
  walk: {
    title: {
      default: 'Walk',
      plural: 'Walks'
    },
    type: 'distance',
    multiplier: 1.5,
    measure: 'distance',
    hasElevation: true
  },
  hike: {
    title: {
      default: 'Hike',
      plural: 'Hikes'
    },
    type: 'distance',
    multiplier: 1.75,
    measure: 'distance',
    hasElevation: true
  },
  ride: {
    title: {
      default: 'Ride',
      plural: 'Rides'
    },
    type: 'distance',
    multiplier: 1,
    measure: 'distance',
    hasElevation: true
  },
  virtualride: {
    title: {
      default: 'Virtual Ride',
      plural: 'Virtual Rides'
    },
    type: 'distance',
    multiplier: 0.75,
    measure: 'distance',
    hasElevation: true
  },
  mountainbikeride: {
    parent: 'ride',
    title: {
      default: 'Mtn Bike Ride',
      plural: 'Mtn Bike Rides'
    },
    type: 'distance',
    multiplier: 1.25,
    measure: 'distance',
    hasElevation: true
  },
  ebikeride: {
    title: {
      default: 'E-Bike Ride',
      plural: 'E-Bike Rides'
    },
    type: 'distance',
    multiplier: 0.5,
    measure: 'distance',
    hasElevation: true
  },
  row: {
    title: {
      default: 'Row',
      plural: 'Rows'
    },
    type: 'distance',
    multiplier: 5,
    measure: 'distance',
    hasElevation: false
  },
  kayak: {
    title: {
      default: 'Kayak',
      plural: 'Kayaks'
    },
    type: 'distance',
    multiplier: 5,
    measure: 'distance',
    hasElevation: false
  },
  canoe: {
    title: {
      default: 'Canoe',
      plural: 'Canoes'
    },
    type: 'distance',
    multiplier: 5,
    measure: 'distance',
    hasElevation: false
  },
  elliptical: {
    title: {
      default: 'Elliptical',
      plural: 'Elliptical'
    },  
    type: 'distance',
    multiplier: 3,
    measure: 'distance',
    hasElevation: true
  },
  swim: {
    title: {
      default: 'Swim',
      plural: 'Swims'
    },
    type: 'distance',
    multiplier: 10,
    measure: 'distance',
    hasElevation: false
  },
  inlineskate: {
    title: {
      default: 'Inline Skate',
      plural: 'Inline Stakes'
    },
    type: 'distance',
    multiplier: 1.5,
    measure: 'distance',
    hasElevation: true
  },
  skateboard: {
    title: {
      default: 'Skateboard',
      plural: 'Skateboards'
    },
    type: 'distance',
    multiplier: 1.5,
    measure: 'distance',
    hasElevation: true
  },
  backcountryski: {
    title: {
      default: 'Backcountry Ski',
      plural: 'Backcountry Skis'
    },
    type: 'distance',
    multiplier: 0.2,
    measure: 'distance',
    hasElevation: true
  },
  alpineski: {
    title: {
      default: 'Alpine Ski',
      plural: 'Alpine Skis'
    },
    type: 'distance',
    multiplier: 0.2,
    measure: 'distance',
    hasElevation: true
  },
  snowboard: {
    title: {
      default: 'Snowboard',
      plural: 'Snowboards'
    },
    type: 'distance',
    multiplier: 0.2,
    measure: 'distance',
    hasElevation: true
  },
  iceskate: {
    title: {
      default: 'Ice Skate',
      plural: 'Ice Skates'
    },
    type: 'distance',
    multiplier: 1.5,
    measure: 'distance',
    hasElevation: false
  },
  nordicski: {
    title: {
      default: 'Nordic Ski',
      plural: 'Nordic Skis'
    },
    type: 'distance',
    multiplier: 2,
    measure: 'distance',
    hasElevation: true
  },
  rollerski: {
    title: {
      default: 'Roller Ski',
      plural: 'Roller Skis'
    },
    type: 'distance',
    multiplier: 2,
    measure: 'distance',
    hasElevation: true
  },
  wheelchair: {
    title: {
      default: 'Wheelchair',
      plural: 'Wheelchair Sessions'
    },
    type: 'distance',
    multiplier: 3,
    measure: 'distance',
    hasElevation: true
  },
  handcycle: {
    title: {
      default: 'Handcycle',
      plural: 'Handcycle Sessions'
    },
    type: 'distance',
    multiplier: 3, 
    measure: 'distance',
    hasElevation: true
  },
  standuppaddling: {
    title: {
      default: 'Stand Up Paddle',
      plural: 'Stand Up Paddles'
    },
    type: 'distance',
    multiplier: 4,
    measure: 'distance',
    hasElevation: false
  },
  golf: {
    title: {
      default: 'Golf',
      plural: 'Golf Rounds'
    },
    type: 'distance',
    multiplier: 0.1,
    measure: 'distance',
    hasElevation: false
  },
  football: {
    title: {
      default: 'Football',
      plural: 'Football Sessions'
    },
    type: 'activity',
    multiplier: 0.2,
    measure: 'distance',
    hasElevation: false
  },
  weighttraining: {
    title: {
      default: 'Weight Training',
      plural: 'Weight Training Sessions'
    },
    type: 'activity',
    multiplier: 0.1,
    measure: 'duration',
    hasElevation: false
  },
  workout: {
    title: {
      default: 'Workout',
      plural: 'Workouts'
    },
    type: 'distance',
    multiplier: 0.1,
    measure: 'duration',
    hasElevation: false
  },
  yoga: {
    title: {
      default: 'Yoga',
      plural: 'Yoga Sessions'
    },
    type: 'activity',
    multiplier: 0.1,
    measure: 'duration',
    hasElevation: false
  },
  stretch: {
    title: {
      default: 'Stretch',
      plural: 'Stretch Sessions'
    },
    type: 'activity',
    multiplier: 0.1,
    measure: 'duration',
    hasElevation: false
  },
  meditate: {
    title: {
      default: 'Meditate',
      plural: 'Meditation Sessions'
    },
    type: 'activity',
    multiplier: 0.1,
    measure: 'duration',
    hasElevation: false
  },
  tennis: {
    title: {
      default: 'Tennis',
      plural: 'Tennis Sessions'
    },
    type: 'distance',
    multiplier: 1.5,
    measure: 'distance',
    hasElevation: false
  },
  snowshoe: {
    title: {
      default: 'Snowshoe',
      plural: 'Snowshoes'
    },
    type: 'distance',
    multiplier: 3.5,
    measure: 'distance',
    hasElevation: true
  },
  kitesurf: {
    title: {
      default: 'Kitesurf',
      plural: 'Kitesurfs'
    },
    type: 'distance',
    multiplier: 0.1,
    measure: 'distance',
    hasElevation: false
  },
  rockclimbing: {
    title: {
      default: 'Rock Climbing',
      plural: 'Rock Climbs'
    },
    type: 'activity',
    multiplier: 0.1,
    measure: 'duration',
    hasElevation: true
  },
  pilates: {
    title: {
      default: 'Pilates',
      plural: 'Pilates'
    },
    type: 'activity',
    multiplier: 0.1,
    measure: 'duration',
    hasElevation: false
  },
  taichi: {
    title: {
      default: 'Tai Chi',
      plural: 'Tai Chi Sessions'
    },
    type: 'activity',
    multiplier: 0.1,
    measure: 'duration',
    hasElevation: false
  },
  stairs: {
    title: {
      default: 'Stairs',
      plural: 'Stairs'
    },
    type: 'distance',
    multiplier: 0.1,
    measure: 'duration',
    hasElevation: true
  }
}

const getDistanceBased = () => {
  return Object.keys(config).reduce((memo, key) => {
    if (config[key].type === 'distance') memo.push(key)
    return memo
  }, [])
}

const getNonDistanceBased = () => {
  return Object.keys(config).reduce((memo, key) => {
    if (config[key].type !== 'distance') memo.push(key)
    return memo
  }, [])
}

const getRepetitionsBased = () => {
  return Object.keys(config).reduce((memo, key) => {
    if (config[key].type === 'repetition') memo.push(key)
    return memo
  }, [])
}

const checkElevationTypes  = (types) => {
  if (!types || !types.length) return false
  return types.some(type => {
    if (config[type] && config[type].hasElevation) return true
    return false
  })
}

const checkForDistanceBased = (types) => {
  return Boolean(types.filter(type => {
    return getDistanceBased().includes(type.toLowerCase())
  }).length)
}

export const getDefaultPointsConfig = (imperial, activityTypesArray) => {
  if (!activityTypesArray || !activityTypesArray.length) return
  const pointsConfig = { imperial }
  for (const type of activityTypesArray) {
    if (!config[type]) continue // custom type
    const { multiplier, measure } = config[type]
    pointsConfig[type] = { multiplier, measure }
  }
  return pointsConfig
}

export const getChildren = (parent) => {
  return Object.keys(config).reduce((memo, key) => {
    if (config[key].parent === parent) memo.push(key)
    return memo
  }, [])
}

export const activityTypeConfig = config
export const activityTypesDistance = getDistanceBased()
export const activityTypesNonDistance = getNonDistanceBased()
export const activityTypesRepetitions = getRepetitionsBased()
export const isDistanceBased = checkForDistanceBased
export const allActivityTypes = Object.keys(config)
export const hasElevationTypes = checkElevationTypes
